/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { ThemeProvider, UnifiedThemeType } from '@oneaudi/unified-web-common';
import { Layout, LayoutItem, TextLink } from '@oneaudi/unified-web-components';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import { UeElement } from '@oneaudi/falcon-tools';
import styled from 'styled-components';
import type { EditorialTextProps } from '../../@types/types';
import { processBodyText } from './processBodyText';
import { ContainerDiv, ButtonContainerDiv, TextWrapperDiv, TextLayout } from './styledComponents';
import { ConsumptionAndEmission } from './ConsumptionAndEmission';

const StyledCTA = styled(TextLink)`
  &:before {
    display: none;
  }
`;

export const EditorialText = ({
  content,
  footnoteReferenceService,
  vueFormatterService,
}: EditorialTextProps) => {
  const {
    bodyText,
    theme,
    justification,
    ctaButtonLabel,
    ctaButtonUrl,
    ctaButtonOpenInNewTab,
    ariaLabel,
    consuptionAndEmission,
    disclaimer,
    bottomMargin,
    reduceTextWidth,
  } = content;

  const themeActive = theme || 'light';

  // const processedBodyText = processBodyText(bodyText);

  const processedCopy = processBodyText(bodyText);

  return (
    <div data-theme-name={themeActive}>
      <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService || {}}>
        <ThemeProvider theme={{ iconBasePath: '/icons', name: themeActive } as UnifiedThemeType}>
          {bodyText && (
            <ContainerDiv
              theme={themeActive}
              justification={justification}
              bottomMargin={bottomMargin}
              reduceTextWidth={reduceTextWidth}
            >
              <TextLayout reduceTextWidth={reduceTextWidth} theme={themeActive}>
                <Layout data-test-id="title" direction="column">
                  <LayoutItem basis="100%">
                    <div>
                      <TextWrapperDiv
                        data-testid="textWrapper"
                        align={justification}
                        theme={themeActive}
                      >
                        {processedCopy}
                      </TextWrapperDiv>
                    </div>
                  </LayoutItem>

                  {ctaButtonUrl?.length > 0 && ctaButtonLabel?.length > 0 && (
                    <LayoutItem basis="auto">
                      <ButtonContainerDiv>
                        <StyledCTA
                          data-testid={ariaLabel || ctaButtonLabel}
                          variant="standalone"
                          size="md"
                          target={ctaButtonOpenInNewTab ? '_blank' : '_self'}
                          href={ctaButtonUrl}
                          onClick={() => {}}
                          iconPosition="trailing"
                          icon="forward"
                        >
                          <UeElement type="text" property="ctaButtonLabel" label="CTA Label">
                            {ctaButtonLabel}
                          </UeElement>
                        </StyledCTA>
                      </ButtonContainerDiv>
                    </LayoutItem>
                  )}
                  {consuptionAndEmission?.length > 0 || disclaimer?.length > 0 ? (
                    <ConsumptionAndEmission
                      consuptionAndEmission={consuptionAndEmission}
                      disclaimer={disclaimer}
                      theme={themeActive}
                      vueFormatterService={
                        vueFormatterService as VueFormatterFeatureServiceInterfaceV1
                      }
                    />
                  ) : null}
                </Layout>
              </TextLayout>
            </ContainerDiv>
          )}
        </ThemeProvider>
      </FootnoteContextProvider>
    </div>
  );
};
