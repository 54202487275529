/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import * as React from 'react';
import { Text } from '@oneaudi/unified-web-components';
import { v4 as uuid } from 'uuid';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import styled from 'styled-components';
import {
  SYS_SPACE_FIXED_500,
  SYS_SPACE_FIXED_800,
  SYS_SPACE_FIXED_600,
  SYS_SPACE_FIXED_1000,
} from '@oneaudi/unified-web-common';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { constants } from './constants';
import { VueServiceHelper, WltpProps } from './vueServiceHelper';

type ConsumptionAndEmissionComponentProps = {
  consuptionAndEmission: string[] | null;
  disclaimer: string;
  theme: string;
  vueFormatterService: VueFormatterServiceInterfaceV1;
};

export const ConsumptionAndEmission: React.FC<ConsumptionAndEmissionComponentProps> = (props) => {
  const { consuptionAndEmission, vueFormatterService, theme, disclaimer } = props;
  let localeService: LocaleServiceV1;

  if (vueFormatterService) {
    localeService = (vueFormatterService as any).localeService;
  }

  const [vueTexts, setVueTexts] = React.useState([] as WltpProps[]);

  React.useEffect(() => {
    const fetchConsumptionAndEmission = async () => {
      consuptionAndEmission &&
        (await VueServiceHelper.getConsumptionAndEmission(
          consuptionAndEmission,
          setVueTexts,
          vueFormatterService,
          localeService,
        ));
    };

    fetchConsumptionAndEmission().catch(console.error);
  }, [consuptionAndEmission]);

  return (
    <ContentContainer theme={theme}>
      <ConsumptionAndEmissionContainer theme={theme}>
        {vueTexts &&
          vueTexts?.map(
            (
              {
                formattedConsumption,
                formattedEmission,
                formattedCo2Class,
                formattedDischargedCo2Class,
                formattedDischargedConsumption,
              },
              index,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text tag="p" variant={{ type: 'copy', order: '2', style: 'normal' }} key={index}>
                <div>
                  {formattedConsumption && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedConsumption}
                    </span>
                  )}
                  {formattedEmission && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedEmission}
                    </span>
                  )}
                  {formattedCo2Class && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedCo2Class}
                    </span>
                  )}
                  {formattedDischargedConsumption && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedDischargedConsumption}
                    </span>
                  )}
                  {formattedDischargedCo2Class && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedDischargedCo2Class}
                    </span>
                  )}
                </div>
              </Text>
            ),
          )}
      </ConsumptionAndEmissionContainer>
      <DisclaimerContainer theme={theme} style={{ display: disclaimer ? 'block' : 'none' }}>
        <br />
        {disclaimer && (
          <Text tag="span" variant={{ type: 'copy', order: '2', style: 'normal' }}>
            {renderTextWithFootnotesReferences(disclaimer)}
          </Text>
        )}
      </DisclaimerContainer>
    </ContentContainer>
  );
};

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width:${constants.displaySizes.tablet})`,
};

interface ConsumptionAndEmissionProps {
  theme: string;
}
interface DisclaimerContainerProps {
  theme: string;
}
interface ContentContainerProps {
  theme: string;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  background-color: ${(props) =>
    props.theme === 'dark' ? constants.color.black : constants.color.white};
  padding: ${SYS_SPACE_FIXED_500};

  @media ${device.tablet} {
    padding: ${SYS_SPACE_FIXED_600} 0px;
  }
  @media ${device.smallDesktop} {
    padding: ${SYS_SPACE_FIXED_800} 0px;
  }
  @media ${device.desktop} {
    padding: ${SYS_SPACE_FIXED_1000} 0px;
  }
  @media ${device.largeDesktop} {
    margin: 0 auto;
    max-width: 1248px;
  }
`;

export const DisclaimerContainer = styled.div<DisclaimerContainerProps>`
  background-color: ${(props) =>
    props.theme === 'dark' ? constants.color.black : constants.color.white};
  position: relative;
  bottom: 0px;
  height: fit-content;
  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
  span sup a {
    font-size: 10px !important;
    font-weight: 500;
    font-feature-settings: normal !important;
  }
  p {
    color: ${(props) => (props.theme === 'dark' ? constants.color.white : constants.color.black)};
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    span {
      margin-right: 16px;
    }
  }
`;

export const ConsumptionAndEmissionContainer = styled.div<ConsumptionAndEmissionProps>`
  background-color: ${(props) =>
    props.theme === 'dark' ? constants.color.black : constants.color.white};
  position: relative;
  bottom: 0px;
  height: fit-content;
  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
  span sup a {
    font-size: 10px !important;
    font-weight: 500;
    font-feature-settings: normal;
  }
  p {
    color: ${(props) => (props.theme === 'dark' ? constants.color.white : constants.color.black)};
  }
  @media ${device.tablet} {
    flex-direction: row;
    span {
      margin-right: 16px;
    }
    span sup a {
      font-size: 10px !important;
      font-weight: 500;
      font-feature-settings: normal;
    }
  }

  @media screen and (max-width: ${constants.displaySizes.smallDesktop}) {
    span {
      :not(:last-child)::after {
        content: '; ';
      }
    }
  }
`;
